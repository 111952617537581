<template>
  <div class="container-fluid" style='padding-top: 20px; padding-bottom: 20px;' data-app>
    <vue-progress-bar></vue-progress-bar>
    <template v-if='plans === null'>
      <div class="loader"></div>
    </template>

    <template v-else>
      <div class="row">
        <div class="mini-calendar col-12">
          <div class="settings">
            <div>Idź do
              <button @click='goToToday'>DZISIAJ</button>
            </div>
            <div>
              <span>Utwórz na kolejnych: </span>
              <select v-model='createPlanForSeveralDays'>
                <option value=1>1 dni</option>
                <option value=2>2 dni</option>
                <option value=3>3 dni</option>
                <option value=4>4 dni</option>
                <option value=5>5 dni</option>
                <option value=6>6 dni</option>
                <option value=7>7 dni</option>
              </select>
              <button @click='createForFewDays(createPlanForSeveralDays)'>Utwórz</button>
            </div>
          </div>

          <div class='week-container' id='week-container'>
            <button @click='scrollLeftRight("left")'>
              <img src='../assets/images/icons/actions-general/arrow-down-black.png' alt="arrow">
            </button>

            <div class="week" id='week'>

              <div class="week-day" v-for='day in daysGenerator("before")' :key='day.getDate()'>
                <span class='day-name'>{{ weekDayNameLong(day) }}</span>
                <template v-if='isPlanForDay(day)'>
                  <router-link
                      style='cursor:pointer;'
                      :to='`${planId(day)}`'
                      class="day-date"
                      :tag='"div"'
                  >
                    <MiniCalendarDay :date='day' :show-icon='isPlanForDay(day)'/>
                  </router-link>
                </template>
                <template v-else>
                  <div class="day-date">
                    <MiniCalendarDay :date='day' />
                  </div>
                </template>
              </div>
              <div class="week-day" v-for='day in daysGenerator("after")' :key='day.getDate()'>
                <span class='day-name'>{{ weekDayNameLong(day) }}</span>
                <template v-if='isPlanForDay(day)'>
                  <router-link
                      :ref='{"myActiveDay": activeDay(day)}'
                      style='cursor:pointer;'
                      :to='`${planId(day)}`'
                      class='day-date'
                      :class='{"day-active": activeDay(day), "editable-day": editableDay(day)}'
                      :tag='"div"'
                  >
                    <MiniCalendarDay :date='day' :show-icon='isPlanForDay(day)'/>
                  </router-link>
                </template>

                <template v-else>
                  <div
                      v-if='editableDay(day)'
                      @click='changeSelectedDay(day)'
                      style='cursor:pointer;'
                      class='day-date'
                      :class='{"day-active": activeDay(day), "editable-day": true}'
                  >
                    <MiniCalendarDay :date='day' />
                  </div>
                  <div v-else class='day-date' :class='{"day-active": activeDay(day)}'>
                    <MiniCalendarDay :date='day' />
                  </div>
                </template>
              </div>

            </div>

            <button @click='scrollLeftRight("right")'>
              <img src='../assets/images/icons/actions-general/arrow-down-black.png' alt="arrow">
            </button>
          </div>

        </div>

        <v-dialog v-model="dialog" max-width="490">
          <v-card>
            <v-card-title class="headline">Utworzenie nowego planu żywienia</v-card-title>

            <v-card-text :style='{textAlign: "left"}'>
              Uwaga! Nie istnieje plan żywionych dla wybranego dnia. Czy chcesz utworzyć nowy plan? <br/>

              <div class="custom-control custom-checkbox my-1 mr-sm-2" style='margin-top:20px !important'>
                <input v-model='fillWithLastPlanData' type="checkbox" class="custom-control-input" id="customControlInline">
                <label class="custom-control-label" for="customControlInline">
                  Uzupełnić plan danymi ostatniego zaakceptowanego planu?
                </label>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn color="green darken-1" @click="dialog = false">Zamknij</v-btn>
              <v-btn color="green darken-1" @click="createPlan(selectedDay)">Utwórz!</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <router-view />

        <div class="mini-calendar-legend">
          <div class='legend-day'>
            <div class="day-date day-active">
              <span>1<small>.01</small></span>
            </div>
            - dzień dzisiejszy
          </div>
          <div class='legend-day'>
            <div class="day-date editable-day">
              <span>1<small>.01</small></span>
            </div>
            - dzień na który możliwe jest utworzenie planu żywienia
          </div>
          <div class='legend-day'>
            <div class="day-date editable-day">
              <span>1<small>.01</small><i class='fe fe-check' /></span>
            </div>
            - dzień w którym już istnieje plan żywienia
          </div>

        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { EventBus } from '@/main';
import { mapState, mapActions } from 'vuex';
import MiniCalendarDay from "@/components/MiniCalendarDayView.vue";

export default {
  name: 'miniCalendarView',
  components: {
    MiniCalendarDay
  },
  data() {
    return {
      today: new Date(),
      selectedDay: null,
      dialog: false,
      createPlanForSeveralDays: 1,
      fillWithLastPlanData: false,
      weekWidth: 0,
      monthNameLong: null,
    };
  },
  beforeDestroy() {
    localStorage.removeItem('calendarWidth');
  },
  computed: {
    ...mapState('plans', ['plans']),
    ...mapState(['selectedDepartment']),
  },
  created() {
    this.plans === null ? this.getPlans() : null;
    this.setCalendarWidth();
    this.setMonthNameLong(new Date());
  },
  methods: {
    ...mapActions('plans', ['getPlans']),
    setCalendarWidth() {
      let customInterval = setInterval(() => {
        // eslint-disable-next-line
        const myEl = $('.week-container .day-active')[0];
        // eslint-disable-next-line
        $(myEl).attr('tabindex', -1).focus();
        // eslint-disable-next-line
        if ($('.week')[0]) {
          // eslint-disable-next-line
          this.weekWidth = $('.week')[0].scrollLeft;
          // eslint-disable-next-line
          localStorage.setItem('calendarWidth', this.weekWidth);
          clearInterval(customInterval)
        }
      }, 300)
      // eslint-disable-next-line
    },
    setMonthNameLong(date) {
      let monthNameLong = date.toLocaleString("pl", { month: "long" });
      this.monthNameLong = monthNameLong.charAt(0).toUpperCase() + monthNameLong.slice(1);
    },
    weekDayNameLong(day) {
      let weekDayNameLong = day.toLocaleString("pl", { weekday: "long" });
      return weekDayNameLong.charAt(0).toUpperCase() + weekDayNameLong.slice(1);
    },
    daysGenerator(type) {
      const daysAfter = [];
      if (type === 'before') {
        for (let i = 1; i <= 14; i++) {
          const nextDay = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - i);
          daysAfter.push(nextDay);
        }
        daysAfter.reverse();
      } else {
        for (let i = 0; i <= 14; i++) {
          const nextDay = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + i);
          daysAfter.push(nextDay);
        }
      }
      return daysAfter;
    },
    activeDay(date) {
      const resetedDate = new Date();
      resetedDate.setHours(0, 0, 0, 0);
      return date.toString() === resetedDate.toString();
    },
    scrollLeftRight(type) {
      const scrollContainer = document.getElementById('week');
      if (type === 'right') {
        window.$('.week').animate({ scrollLeft: scrollContainer.scrollLeft + 250 }, 300);
      } else {
        if (scrollContainer.scrollLeft > 0) {
          window.$('.week').animate({ scrollLeft: scrollContainer.scrollLeft - 250 }, 300);
        }
      }
    },
    isPlanForDay(date) {
      let dateToCheck = date.setHours(0, 0, 0, 0);
      return this.plans.some(value => new Date(value.planDate).setHours(0, 0, 0, 0) === dateToCheck);
    },
    planId(day) {
      let dayToCheck = day.setHours(0, 0, 0, 0);
      const plan = this.plans.find(value => new Date(value.planDate).setHours(0, 0, 0, 0) === dayToCheck);
      return plan ? `/plans/show/${plan.uuid}` : undefined;
    },
    editableDay(day) {
      if (new Date(day).setHours(0, 0, 0, 0) === new Date(this.today).setHours(0, 0, 0, 0)) {
        return true;
      } else {
        if (day <= new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 7)) {
          return true;
        }
      }
    },
    goToToday() {
      const width = localStorage.getItem('calendarWidth');
      window.$('.week').animate({ scrollLeft: width }, 500);
    },
    changeSelectedDay(day) {
      this.selectedDay = new Date(day);
    },
    createPlan(day) {
      const planDate = new Date(day).toLocaleString("default", { year: "numeric", month: "2-digit", day: "2-digit" });
      this.dialog = false;
      this.$store.dispatch('plans/createPlan', {
        fillWithLastPlanData: this.fillWithLastPlanData,
        planDate
      }).then(() => {
        this.fillWithLastPlanData = false;
      })
    },
    createForFewDays(daysNumber) {
      if (this.$route.params.id) {
        this.$store.dispatch('plans/createPlans', {
          daysNumber
        }).then(() => {
          this.fillWithLastPlanData = false;
        })
      } else {
        EventBus.$emit('triggerAlert', {
          message: 'Musisz wybrać plan',
          triggerManualError: true,
          type: 'planMustBeSelected'
        });
      }
    },
  },
  watch: {
    selectedDay() {
      if (this.plans.length > 0) {
        for (const value of this.plans) {
          if (new Date(value.planDate).toString() === this.selectedDay.toString()) {
            return true;
          } else {
            this.dialog = true;
          }
        }
      } else {
        this.dialog = true;
      }
    },
    selectedDepartment() {
      this.$route.params.id ? this.$router.push('/plans') : null
      this.setCalendarWidth()
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/css/variables';

.mini-calendar-legend {
  display: flex;
  flex-direction: column;
  // margin-top: 20px;
  .legend-day {
    display: flex;
    align-items: center;
    // width: 70px;
    margin: 0 10px 10px 10px;

    .month-name {
      font-size: 1.5em;
      font-weight: 700;
    }

    .day-name {
      display: block;
      font-weight: bolder;
      margin-bottom: 10px;
    }

    .day-date {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #EAECF6;
      color: #3C3C42;
      font-weight: bold;
      // padding: 10px 10px 10px 15px;
      padding: 0 10px;
      width: 70px;
      margin-right: 10px;
      height: 45px;
      border-radius: 5px;

      small {
        font-size: 60%;
        font-weight: 700;
      }

      span {
        font-size: 1.3em;
        position: relative;
      }

      i {
        font-size: 20px;
        position: absolute;
      }
    }

    .day-active {
      background-color: #00BAFF !important;
      color: white;
    }

    .editable-day {
      background-color: #96E3FF;
      cursor: pointer;
    }
  }
}

.mini-calendar {
  margin-bottom: 25px;
}

.settings {
  display: flex;
  justify-content: space-between;
  font-weight: bold;

  button {
    background-color: transparent;
    border: none;
    color: $action-color;
    cursor: pointer;
    font-weight: bolder;
    border: 2px solid $action-color;
    padding: 3px 8px;
    border-radius: 5px;
  }

  span {
    font-weight: normal;
    margin-right: 10px;
  }

  select {
    min-width: 200px;
    appearance: none;
    background-color: transparent;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #c4c0d8;
    background: #342f81;
    padding: 5px 1.75rem 5px 0.5rem;
    background: url('../assets/images/icons/actions-general/arrow-down-black.png');
    background-repeat: no-repeat;
    background-position: center right;
    background-position-x: 95%;
    padding: 2px 7px;
    border-color: #7a71a7;
    padding-right: 30px;
  }
}

.week-container {
  display: flex;
  align-items: flex-end;

  & > button {
    height: 60px;
    border-radius: 4px;
    background-color: #EAECF6;
    border: none;
    padding: 0 2px;
    margin-bottom: 17px;
    cursor: pointer;

    &:first-child {
      margin-right: 20px;

      img {
        transform: rotate(90deg);
      }
    }

    &:last-child {
      margin-left: 20px;

      img {
        transform: rotate(-90deg);
      }
    }
  }
}

.week {
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: column;
  align-items: end;
  justify-items: center;
  overflow: auto;
  margin-top: 20px;
  position: relative;
  padding-bottom: 10px;
  // &::-webkit-scrollbar {
  //     display:none;
  // }
  &::-webkit-scrollbar {
    height: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #00BAFF;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.week-day {
  display: inline-block;
  width: 128px;
  margin: 0 5px;

  .month-name {
    font-size: 1.5em;
    font-weight: 700;
  }

  .day-name {
    display: block;
    font-weight: bolder;
    margin-bottom: 10px;
  }

  .day-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #EAECF6;
    color: #3C3C42;
    font-weight: bold;
    // padding: 10px 10px 10px 15px;
    padding: 0 15px;
    height: 60px;
    border-radius: 5px;

    small {
      font-size: 60%;
      font-weight: 700;
    }

    span {
      font-size: 1.5em;
      position: relative;
    }

    i {
      font-size: 20px;
      position: absolute;
    }
  }

  .day-active {
    background-color: #00BAFF !important;
    color: white;
  }

  .editable-day {
    background-color: #96E3FF;
    cursor: pointer;
  }
}

.router-link-active {
  border: 1px solid $dark-bg-col;
}
</style>
