import { axios_instance as axios } from "@/axios-config.js";
import { EventBus } from "@/main";

export default {
  namespaced: true,

  state: {
    plans: null,
    nourishedPlanContract: {
      id: null,
      uuid: null,
      dateFrom: null,
      dateTo: null,
      dayToSendNutritionPlanId: null,
      timeToSendNutritionPlan: null,
      company: {
        name: null,
        city: null,
        address: null,
        postalCode: null,
        companyDepartment: {
          id: null,
          name: null,
          city: null,
          address: null,
          postalCode: null,
          isActive: null,
          diets: [],
        },
      }
    },
    nourishedPlan: {
      uuid: null,
      number: null,
      textNumber: null,
      planDate: null,
      createdBy: null,
      status: null,
      notes: null,
      createdAt: null,
      updatedAt: null,
    },
    nourishedPlanDietsReadOnly: [],
    nourishedPlanDietsDraft: [],
    serverTime: null,
    isBeingUpdated: false,
    incoming_info: false,
  },

  getters: {},

  mutations: {
    SET_PLANS(state, payload) {
      state.plans = payload;
    },
    // ADD_PLAN(state, payload) {
    //   state.plans.push(payload);
    // },
    // ADD_PLANS(state, payload) {
    //   state.plans = [...state.plans, ...payload];
    // },
    SET_PLAN_DATA(state, {contract, companyDepartment, nourishedPlanDiets, ...rest}) {
      state.nourishedPlan = { ...rest };
      state.nourishedPlanContract = contract ;
      state.companyDepartment = companyDepartment;
      state.nourishedPlanDietsReadOnly = nourishedPlanDiets;
      // state.nourishedPlanDietsDraft = nourishedPlanDiets ? JSON.parse(JSON.stringify(nourishedPlanDiets)) : [];
    },
    SET_SERVER_TIME(state, payload) {
      payload.type === "reset" ? (state.serverTime = null) : (state.serverTime = new Date(payload.server_time));
    },
    UPDATE_LOADING_STATUS(state, payload) {
      state.isBeingUpdated = payload;
    },
    SET_INCOMING_INFO(state, payload) {
      state.incoming_info = payload;
    },
  },

  actions: {
    getPlans({ commit, rootState, state }, type) {
      if (rootState.selectedDepartment && rootState.selectedDepartment.uuid) {
        if (type !== "loop") {
          commit("SET_PLAN_DATA", { contract: null, nourishedPlanDiets: [], ...state.nourishedPlan });
          commit("SET_PLANS", null);
        }
        return axios.get(`v1/nourished-plan/nourished-plans/company-departament/${rootState.selectedDepartment.uuid}`)
          .then((response) => {
            if (type === "loop" && (state.plans.length !== response.data.length)) {
              commit("SET_INCOMING_INFO", true);
            }
            commit("SET_PLANS", response.data);

            return true;
          }).catch((error) => {
            console.error(error);

            if (error.response.status === 401) {
              return false; // Zwracam TRUE dla setInterval, aby już nie strzelał żądań do API gdz JWT TOKEN wygasł
            }

            EventBus.$emit("triggerAlert", {
              message: error.response.data.message,
              triggerManualError: true,
              type: "getNourishedPlans",
            });
            console.error("Error:", error);

            return true; // Zwracam FALSE dla setInterval, ponieważ dalej JWT TOKEN jest ważny
          });
      }
    },
    getServerTime({ commit }) {
      axios.get("v1/server-time")
        .then((response) => {
          commit("SET_SERVER_TIME", response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    getNourishedPlanData({ commit, dispatch }, planUuid) {

      dispatch("getServerTime");

      axios.get(`v1/nourished-plan/nourished-plans/${planUuid}`)
        .then((response) => {
          commit("SET_PLAN_DATA", response.data);
        })
        .catch((error) => {
          commit("SET_PLAN_DATA", []);
          EventBus.$emit("triggerAlert", {
            message: error.response.data.message,
            triggerManualError: true,
            type: "getNourishedPlanData",
          });
          console.error("Error:", error);
        });
    },
    createPlan({ rootState, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axios.post("v1/nourished-plan/nourished-plans", {
          companyDepartment: rootState.selectedDepartment.uuid,
          planDate: payload.planDate,
          fillWithLastPlanData: payload.fillWithLastPlanData
        }).then((response) => {
          // commit("ADD_PLAN", response.data);
          EventBus.$emit("triggerAlert", {
            message: "Plan został utworzony pomyślnie",
            type: "createPlan",
          });
          resolve(response.data.nourishedPlanUuid);
          dispatch("getPlans", 'loop');
        }).catch((e) => {
          EventBus.$emit("triggerAlert", {
            message: e.response.data,
            triggerManualError: true,
            type: "createPlan",
          });
          reject(e);
        });
      });
    },
    createPlans({ commit, state, dispatch }, payload) {
      const url = `v1/nourished-plan/nourished-plans/${state.nourishedPlan.uuid}/for-next-days`;
      axios.post(url, {
          howManyDays: payload.daysNumber,
        },
        ).then((response) => {
          commit("ADD_PLANS", response.data["hydra:member"]);
          EventBus.$emit("triggerAlert", {
            message: "Plany zostały utworzone pomyślnie",
            type: "createForFewDays",
          });
          dispatch("getPlans", 'loop');
        }).catch((error) => {
          EventBus.$emit("triggerAlert", {
            message: error.response.data.message,
            triggerManualError: true,
            type: "createForFewDays",
          });
          console.error(error.response.data);
        });
    },
    updateDocument({ commit, state, dispatch }) {
      commit("UPDATE_LOADING_STATUS", true);

      return new Promise((resolve, reject) => {
        axios.put(`v1/nourished-plan/nourished-plans/${state.nourishedPlan.uuid}`,
          { notes: state.nourishedPlan.notes },
        ).then(() => {
            EventBus.$emit("triggerAlert", {
              message: "Plan został zaktualizowany poprawnie.",
              type: "updateDocument",
            });
            // commit("SET_PLAN_DATA", response.data);
            dispatch("getNourishedPlanData", state.nourishedPlan.uuid)

            resolve();
        }).catch(error => {
            EventBus.$emit("triggerAlert", {
              message: error.response.data.message,
              triggerManualError: true,
              type: "updateDocument",
            });
            reject(error);
          })
          .finally(() => commit("UPDATE_LOADING_STATUS", false));
      });
    },
    updateDiet({ commit, state, dispatch }, payload) {
      commit("UPDATE_LOADING_STATUS", true);

      return new Promise((resolve, reject) => {
        axios.put(`v1/nourished-plan/nourished-plan-diets/${payload.nourishedPlanDietUuid}`,
          { notes: payload.notes },
        ).then(() => {
          EventBus.$emit("triggerAlert", {
            message: "Uwagi do diety zostały zaktualizowane poprawnie.",
            type: "updateDiet",
          });

          dispatch("getNourishedPlanData", state.nourishedPlan.uuid)

          resolve();
        }).catch(error => {
          EventBus.$emit("triggerAlert", {
            message: error.response.data.message,
            triggerManualError: true,
            type: "updateDiet",
          });
          reject(error);
        })
          .finally(() => commit("UPDATE_LOADING_STATUS", false));
      });
    },
    changePlanPosition({ commit, state, dispatch }, payload) {
      commit("UPDATE_LOADING_STATUS", true);

      return new Promise((resolve, reject) => {
        axios.put("v1/nourished-plan/nourished-plan-position/" + payload.positionUuid,
          payload.dataForSending
        ).then(() => {
            EventBus.$emit("triggerAlert", {
              message: 'Pozycja planu została zaktualizowana poprawnie.',
              type: "updatePlanPosition",
            });

            dispatch("getNourishedPlanData", state.nourishedPlan.uuid);

            resolve();
        }).catch((error) => {

            reject(error);

            EventBus.$emit("triggerAlert", {
              message: error.response.data.message,
              type: "updatePlanPosition",
              triggerManualError: true
            });
          })
          .finally(() => commit("UPDATE_LOADING_STATUS", false));
      });
    },
  },
};
