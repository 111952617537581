<template>
    <input
      type="number"
      min="0"
      v-model.number='ownQuantity'
      @keyup.enter="sendChangedData"
      @blur="sendChangedData"
      class="w-100"
      v-bind:class="{ 'form__input--loader' : isBeingUpdated }"
    >
</template>

<script>
import { EventBus } from '@/main';
import { mapState } from 'vuex';

export default {
  props: {
    peopleNumber: {
      type: Number,
      default: null,
    },
    uuid: {
      type: String,
      default: null,
    },
    diet: {
      type: Object,
      default: () => {},
    },
    correction: {
      type: Boolean,
      default: false,
    },
    correctionValue: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      ownQuantity: this.correctionValue === null ? this.peopleNumber : this.correctionValue,
    };
  },
  computed: {
    ...mapState('plans',['isBeingUpdated']),
  },
  methods: {
    sendChangedData() {
      if (typeof this.ownQuantity === 'string') {
        EventBus.$emit('triggerAlert', {
          message: 'Podana wartość jest błędna',
          triggerManualError: true,
          type: 'inputError'
        });  // Aktualizowana wartość nie może być pusta
        return false;
      }

      EventBus.$emit('updatePosition', {
        uuid: this.uuid,
        correction: this.correction,
        value: this.ownQuantity,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/css/variables';
td {
  border:none !important;
}
input {
  width: 100%;
  color: $tbody-txt-col;
  border: 1px solid #c4c0d8;
  padding: 2px 5px;
  border-radius: 3px;
  outline: none;
  background-color:white;
  &:hover {
    border:1px solid black;
  }
}
</style>
